import axios from "axios";

export default {
  addNewSystem(newSystem) {
    return axios
      .post(`${process.env.VUE_APP_AIS_BASE_URL_OLD}/systems`, newSystem)
      .then(response => response.data);
  },
  getNewestVersionOfSystem(systemid) {
    return axios.get(
        process.env.VUE_APP_AIS_BASE_URL_OLD + "/systems/" +
        systemid.substring(1)+
        "/current"
      );
  }
};

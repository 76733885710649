import axios from "axios";

export default {
  getBibliotheken(systemid) {
    return axios.get(
      process.env.VUE_APP_AIS_BASE_URL_OLD +
        "/bibliothek/" +
        systemid.substring(1) 
    );
  },  
  updateBibliotheken(bibliotheken, systemid) {
    return axios
      .post(
        process.env.VUE_APP_AIS_BASE_URL_OLD +
          "/systems/" +
          systemid.substring(1) +
          "/bibliotheken/",
          bibliotheken
      )
      .catch(error => {
        throw error.response.data.errorMessage;
      });
  }
};

<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="bibliotheken"
      sort-by="bibliothek"
      class="elevation-1"
      dense
    >
      <template #item.bibliothek.lizenz="{item}">
        {{ lizenzText(item) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <help />
          <v-toolbar-title>Bibliotheken</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Hinzufügen
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <p v-html="formDescription" class="subtitle-2 text-left"></p>
                <v-divider class="mx-1"></v-divider>
                <v-container>
                  <v-form ref="form_bibliothekliste" v-model="valid">
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.bibliothek.bezeichnung"
                          label="Bibliothek"
                          :rules="rules.bibliothekRule"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.bibliothek.version"
                          label="Version"
                          :rules="rules.versionRule"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-radio-group v-model="radios" dense>
                          <template v-slot:label>
                            <div>Lizenzmodell</div>
                          </template>
                          <v-radio value="ossLizenz">
                            <template v-slot:label>
                              <div>Open Source</div>
                            </template>
                          </v-radio>
                          <v-radio value="otherLizenz">
                            <template v-slot:label>
                              <div>Andere</div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="editedItem.bibliothek.lizenz"
                          label="Lizenz"
                          v-if="selectedRadio == 'otherLizenz'"
                          :rules="rules.lizenzRule"
                        ></v-text-field>

                        <v-select
                          v-model="editedItem.ossLizenz"
                          :items="allosslizenzen"
                          item-text="bezeichnung"
                          item-value="id"
                          label="Lizenz"
                          return-object
                          v-if="selectedRadio == 'ossLizenz'"
                          autocomplete
                          dense
                          :rules="rules.ossLizenzRule"
                          required
                        >
                        </v-select>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          v-model="editedItem.bibliothek.beschreibung"
                          value="beschreibung"
                          label="Beschreibung"
                          hint="Beschreiben Sie hier die Bibliothek."
                          rows="2"
                          :rules="rules.beschreibungRule"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          v-model="editedItem.bemerkung"
                          label="Bemerkungen"
                          hint="Beschreiben Sie hier die Verwendung der Bibliothek."
                          :rules="rules.bemerkungRule"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close">
                  Abbruch
                </v-btn>
                <v-btn color="primary" text @click="save">
                  Übernehmen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Möchten Sie die Technologie entfernen?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDelete">Abbruch</v-btn>
                <v-btn color="primary" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span> {{ item.bibliothek.bezeichnung }} bearbeiten</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              @click="deleteItem(item)"
              color="error"
              v-bind="attrs"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span> {{ item.bibliothek.bezeichnung }} löschen</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-btn
            class="mr-1"
            outlined
            @click="saveBibliotheken"
            v-if="bibliotheken.length > 0 || form_saved == false"
          >
            speichern
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-btn
            outlined
            @click="initialize"
            v-if="bibliotheken.length > 0 || form_saved == false"
          >
            Zurücksetzen
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar" :timeout="snackbar_timeout">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Schliessen
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import SystemService from "@/services/old/SystemService.js";
import BibliothekService from "@/services/old/BibliothekService.js";
import Help from "@/components/old/help/BibliotheklisteHelp.vue";
export default {
  components: { Help },
  data: () => ({
    valid: true,
    ossValid: true,
    dialog: false,
    dialogDelete: false,
    selectedRadio: "ossLizenz",
    headers: [
      { text: "Bibliothek", value: "bibliothek.bezeichnung" },
      { text: "Version", value: "bibliothek.version" },
      { text: "Lizenz", value: "bibliothek.lizenz" },
      { text: "Beschreibung", value: "bibliothek.beschreibung" },
      { text: "Bemerkungen", value: "bemerkung" },
      { text: "Aktionen", value: "actions", sortable: false }
    ],
    rules: {
      bibliothekRule: [v => !!v || "Bibliothek erforderlich"],
      versionRule: [v => !!v || "Version erforderlich"],
      lizenzRule: [v => !!v || "Lizenz erforderlich"],
      ossLizenzRule: [v => !!v || "Lizenz erforderlich"],
      beschreibungRule: [v => !!v || "Beschreibung erforderlich"],
      bemerkungRule: [v => !!v || "Bemerkung erforderlich"]
    },
    bibliotheken: [],
    editedIndex: -1,
    editedItem: {
      bibliothek: {},
      bemerkung: "",
      ossLizenz: {}
    },
    defaultItem: {
      bibliothek: {},
      bemerkung: "",
      ossLizenz: {}
    },
    isEditModus: false,
    snackbar: false,
    message: "",
    snackbar_timeout: 2000,
    form_saved: true
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Bibliothek hinzufügen"
        : "Bibliothek ändern";
    },
    formDescription() {
      return "Spezifizieren Sie die in der Architektur verwendeten Bibliotheken. </br>Es sollten nur Bibliotheken spezifiziert werden, die zusätzlich zu der Laufzeitumgebung oder anderer Technologien hinzugefügt werden.";
    },
    allosslizenzen() {
      return this.$store.getters.lizenzen;
    },
    radios: {
      get() {
        if (this.editedItem.ossLizenz != null) {
          return "ossLizenz";
        } else {
          return "otherLizenz";
        }
      },
      set(value) {
        this.selectedRadio = value;
      }
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  created() {
    this.initialize();
    this.$store.dispatch("getOssLizenzen");
  },
  methods: {
    initialize() {
      this.loadBibliotheken();
    },
    async loadBibliotheken() {
      let resp = await BibliothekService.getBibliotheken(
        this.$store.getters.currentSystemId
      );
      this.bibliotheken = resp.data;
      if (resp.status == 200) {
        this.message = "Technologieen gelesen.";
      } else {
        this.message = resp.data;
      }
    },
    editItem(item) {
      this.editedIndex = this.bibliotheken.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.isEditModus = true;
    },
    deleteItem(item) {
      this.editedIndex = this.bibliotheken.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.bibliotheken.splice(this.editedIndex, 1);
      this.closeDelete();
      this.message = "Änderungen wurden noch nicht gespeichert.";
      this.snackbar = true;
      this.form_saved = false;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.isEditModus = false;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.validate();
      if (this.valid && this.ossValid) {
        if (this.editedIndex > -1) {
          if (this.selectedRadio == "otherLizenz") {
            this.editedItem.ossLizenz = null;
          } else {
            this.editedItem.bibliothek.lizenz = null;
          }
          Object.assign(this.bibliotheken[this.editedIndex], this.editedItem);
        } else {
          this.bibliotheken.push(this.editedItem);
        }
        this.editedItem = Object.assign({}, this.defaultItem);
        this.close();
        this.message = "Änderungen wurden noch nicht gespeichert.";
        this.snackbar = true;
        this.form_saved = false;
      }
    },
    async saveBibliotheken() {
      try {
        let resp = await BibliothekService.updateBibliotheken(
          this.bibliotheken,
          this.$store.getters.currentSystemId
        );
        this.bibliotheken = resp.data;
        if (resp.status == 200) {
          this.message = "Änderungen wurden gespeichert.";
          this.form_saved = true;
        } else {
          this.message = resp.errorMessage;
        }
        this.refreshCurrentSystem();

      } catch (err) {
        this.message = err;
        this.initialize();
      }
      this.snackbar = true;
    },
     async refreshCurrentSystem() {
      let resp1 = await SystemService.getNewestVersionOfSystem(
        this.$store.getters.currentSystemId
      );
      var currSystem = resp1.data;
      this.$store.dispatch("registerSystem", currSystem);

      if (resp1.status == 200) {
        this.message = "Änderungen wurden gespeichert.";
        this.form_saved = true;
      } else {
        this.message = resp1.errorMessage;
      }
    },
    bibliothekBezeichnungText(bibliothek) {
      if (bibliothek) {
        return bibliothek.bezeichnung + "-" + bibliothek.version;
      }
    },
    lizenzText(item) {
      if (item && item.bibliothek.lizenz) {
        return item.bibliothek.lizenz;
      } else if (item.ossLizenz) {
        return item.ossLizenz.bezeichnung;
      }
    },
    validate() {
      this.$refs.form_bibliothekliste.validate();
      if (this.selectedRadio == "ossLizenz" && !this.editedItem.ossLizenz.id) {
        this.ossValid = false;
      } else {
        this.ossValid = true;
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.form_saved) {
      const answer = window.confirm(
        "Änderungen sind noch nicht gespeichert worden.\nSollen die Änderungen verworfen werden?"
      );
      if (answer) {
        this.form_saved = true;
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
};
</script>
